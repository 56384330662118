import { intl } from 'di18n-react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { Input, Tooltip, Popover } from 'antd';
import classBind from 'classnames/bind';
import { getSearchData } from '@/service/knowledge/search';
import SearchItem from '@/components/SearchItem';
import { FileType } from '@/constants/cooper';
import NoMore from '@/components/NoMore';
import { PAGE_SIZE } from '@/constants';
import SearchOnScope from './SearchScope';
import { formateDesc, getSystemType, getUserNameFromCookie, formateSource } from '@/utils';
import SpinRender from '@/components/SpinRender';
import { getSearchTypeMap, scopeMap } from '@/constants/search';
import InfiniteScroll from 'react-infinite-scroller';
import HistoryResultList from '@/components/AdvanceSearch/HistoryResultList';
import styles from './style.module.less';
import { useLocation, useParams } from 'react-router-dom';
// import { openDCSearch, isSupported } from './dcSdk';
// import { isDC } from '@/utils/index';


const cx = classBind.bind(styles);

const GlobalSearch = (props) => {
  const {
    openSearchModal,
    clearSearchScope,
    searchScopeId,
    displayName,
    onFocus,
  } = props;
  const [searchList, setSearchList] = useState([]);
  const [value, setValue] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [traceId, setTraceId] = useState(null);
  const searchInput = useRef();
  const valueRef = useRef(value);
  const { shareId } = useParams();
  const [searchTypeMap] = useState(getSearchTypeMap());
  const { pathname } = useLocation();
  const [cnInputting, setCnInputting] = useState(false);
  const isShare = !!shareId;

  useEffect(() => {
    valueRef.current = value; // 为了keydown事件触发时获取到最新的value
  }, [value]);

  const keydownFn = (event) => {
    // ctrl + J
    if (
      (event.ctrlKey && event.keyCode === 74)
      || (event.metaKey && event.keyCode === 74)
    ) {
      // windows默认键盘事件阻止
      event.preventDefault();
      event.returnValue = false;
      openAdvanceSearch();
    } else {
      event.returnValue = true;
    }
  };

  useEffect(() => {
    // TODO:dc搜索用这个
    // if (!isDC()) {
    //   document.addEventListener('keydown', keydownFn);
    // }
    // return () => {
    //   !isDC() && document.removeEventListener('keydown', keydownFn);
    //   valueRef.current = null;
    //   searchInput.current = null;
    // };
    document.addEventListener('keydown', keydownFn);
    return () => {
      document.removeEventListener('keydown', keydownFn);
      valueRef.current = null;
      searchInput.current = null;
    };
  }, [value]);

  const handleVisibleChange = (v) => {
    // input focus状态时，不关闭popover
    // if (document.querySelector('#searchInput') === document.activeElement) {
    //   setVisible(true);
    //   return;
    // }
    // TODO:DC 搜索框存在时，阻止popover展示
    // setVisible(isSupported() ? false : v);
    setVisible(v);
  };

  const afterClickItem = () => {
    searchInput.current.blur();
    window.__OmegaEvent('ep_search_quick_recently_visit_ck', '快捷搜索-最近浏览-访问文件', {
      source: formateSource(pathname),
    });
  };

  const searchClickCallBack = (position, clickItem) => {
    const omegaAttrs = {
      ldap: getUserNameFromCookie(),
      origin: 1,
      keyword: valueRef.current,
      tabtype: 0,
      clickposition: position,
      resourcetype: clickItem.resourceType,
      spacetype: clickItem.spaceType,
      score: clickItem.score,
      result: clickItem.resourceId,
    }

    if (traceId) {
      omegaAttrs['traceid'] = traceId;
    }
    // 更新时间
    if (clickItem.modifyTime) {
      omegaAttrs['updatetime'] = clickItem.modifyTime;
    }

    // 浏览时间
    if (clickItem.visitTime) {
      omegaAttrs['browsetime'] = clickItem.visitTime;
    }

    window.__OmegaEvent('ep_cooper_search_list_ck', '点击搜索结果', omegaAttrs);
    window.__OmegaEvent('ep_search_quick_visit_ck');
  }

  const openAdvanceSearch = (e) => {
    if (e && e.keyCode) {
      // 中文状态下enter不打开高级搜索
      if (e.keyCode === 229) return
    }

    // window.__OmegaEvent('ep_dkpc_dk_advancedsearch_ck');
    window.__OmegaEvent('ep_search_advanced_keyboard_ck', '高级搜索-快捷键', {
      source: formateSource(pathname),
      type: '快捷键',
    });
    setVisible(false);
    openSearchModal(valueRef.current);
    setValue();
  };

  const loadOptions = async (pageNum = 0, key, cooperId) => {
    let v = key ?? value;
    setLoading(true);
    setHasMore(true);

    if (!v) {
      setLoading(false);
      return;
    }
    let opts = {
      key: v?.trim() || '',
      pageNum,
      pageSize: PAGE_SIZE,
      searchType: searchTypeMap.allPages?.type,
    }
    // 暂不对最近浏览进行范围筛选
    if ([scopeMap.PERSONAL, scopeMap.TEAMS, scopeMap.DKS].includes(cooperId)) {
      opts.locationIds = [cooperId]
    } else if (cooperId === scopeMap.STAR) {
      opts.moduleTypes = [cooperId]
    } else if (cooperId) {
      opts.locationIds = [cooperId]
    }
    // 默认选中当前空间
    let res = await getSearchData(opts);
    setTraceId(res?.traceId);

    window.__OmegaEvent('ep_search_ck', '快捷搜索', {
      source: formateSource(pathname),
    });

    window.__OmegaEvent('ep_search_quick_ck');
    
    const omegaAttrs = {
      ldap: getUserNameFromCookie(),
      origin: 1,
      keyword: v?.trim() || '',
      tabtype: 0,
    }

    if (res?.traceId) {
      omegaAttrs['traceid'] = res.traceId;
    }

    window.__OmegaEvent('ep_cooper_search_data_ck', '搜索', omegaAttrs);

    if (res) {
      const { items = [] } = res?.allPages;
      setSearchList([...searchList, ...items]);
      if (items.length < PAGE_SIZE) setHasMore(false);
    }

    setLoading(false);
  };

  const onSearch = useMemo(() => {
    return debounce(loadOptions, 800);
  }, []);

  const noHistory = () => (
    <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
      {intl.t('没有最近浏览记录')}
    </div>
  );

  // const inWebkit = useMemo(() => {
  //   return isWebkit();
  // }, []);

  const onCompositionStart = () => {
    setCnInputting(true);
  };

  const onCompositionEnd = (e) => {
    setCnInputting(false);
    // if (inWebkit) {
    setLoading(true);
    setValue(e.target.value);
    setSearchList([]);
    onSearch(0, e.target.value, searchScopeId);
    handleVisibleChange(true);
    // }
  };

  const quickSearchContent = (
    <>
      <div className={cx('sticky-content')}>
        {
          <div className={cx('search-more-wrap')}>
            <div
              onClick={() => {
                window.__OmegaEvent('ep_dkpc_dk_search_recent_viewmore_ck');
                window.__OmegaEvent('ep_search_quick_recently_advanced_ck', '快捷搜索-最近浏览-高级搜索', {
                  source: formateSource(pathname),
                  type: '浮层快捷方式',
                });
                openAdvanceSearch();
              }}
              className={cx('search-more')}
            >
              <span className={cx('more-icon-wrap')}>
                <i className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`} />
              </span>
              <span className={cx('more-b')}>{intl.t('高级搜索')}</span>
            </div>
          </div>
        }
      </div>
      <div
        className={cx('search-popover-content', 'search-popover-content-os-flag')}
        style={{ maxHeight: 'min(100vh - 180px, 596px)' }}
      >
        {!value ? (
          <HistoryResultList
            afterClickItem={afterClickItem}
            emptyRender={() => noHistory()}
            searchScopeId={searchScopeId}
            type={searchTypeMap.allPages.type}
          />
        ) : (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={(args) => loadOptions(args, null, searchScopeId)}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            {searchList.length > 0
              && searchList.map((v, index) => (
                <SearchItem
                  key={index}
                  data={{
                    ...v,
                    scopeName: v?.spaceName,
                    resourceType: v?.resourceType,
                    resourceName: v?.resourceName,
                    teamId: [FileType.SPACE, FileType.SPACE_V2, FileType.SPACE_V3].includes(v?.spaceType) ? v?.spaceId : null,
                    markedQuickVisit: v?.markedQuickVisit || false,
                    modifyTime:
                      v?.resourceType === 'DK_PAGE'
                        ? v?.modifyTime
                        : v?.modifyTime,
                    desc:
                      v?.resourceDesc || v?.description
                        ? formateDesc(
                          v?.resourceDesc || v?.description,
                          440,
                          value.length,
                        )
                        : undefined,
                    resourceOwner: [FileType.KNOWLEDGE, FileType.SPACE].includes(
                      v?.resourceType,
                    )
                      ? v?.spaceOwnerName
                      : v?.resourceOwner,
                    knowledgeId: v?.spaceId,
                    dkShareType: v?.shareType, // 实际不会搜出来知识库分享页面数据
                    pageId: v?.resourceId,
                    shareId: v?.manageQuickAccessInfo?.shareId || v?.shareId, // cooper的分享
                    shareLink: v?.shareLink,
                    parentId: v?.parentId,
                  }}
                  position={index}
                  callback={searchClickCallBack}
                />
              ))}
            <SpinRender loading={loading} />
            {!loading && searchList.length === 0 && (
              <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
                {intl.t('没有搜索到您想要的内容')}
              </div>
            )}
            {!loading && !hasMore && searchList.length !== 0 && (
              <NoMore />
            )}
          </InfiniteScroll>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={cx('global-search-wrap')}>
        <Popover
          content={quickSearchContent}
          visible={visible}
          trigger="click"
          overlayClassName={cx('global-search-popover', {
            'share-search-popover': isShare,
          })}
          autoAdjustOverflow={false}
          placement={isShare ? 'bottomRight' : 'bottom'}
          onVisibleChange={handleVisibleChange}
          destroyTooltipOnHide={true} // 为什么不生效？
        >
          <Input
            // id='searchInput'
            placeholder={intl.t('全新升级，快来体验')}
            // onClick={isSupported() ? openDCSearch : () => {}}
            allowClear
            value={value}
            ref={searchInput}
            onFocus={onFocus}
            // onBlur={() => {
            //   handleVisibleChange(false)
            // }}
            onChange={(e) => {
              if (cnInputting) {
                setValue(e.target.value);
              } else {
                setLoading(true);
                setValue(e.target.value);
                setSearchList([]);
                onSearch(0, e.target.value, searchScopeId);
                handleVisibleChange(true);
              }
            }}
            onPressEnter={openAdvanceSearch}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={onCompositionEnd}
            addonBefore={
              <SearchOnScope
                displayName={displayName}
                clearScope={() => {
                  clearSearchScope();
                  onSearch(0, value, undefined);
                }}
              />
            }
            addonAfter={
              <span
                onClick={() => {
                  openAdvanceSearch();
                  window.__OmegaEvent('ep_search_advanced_ck', '高级搜索-点击图标', {
                    source: formateSource(pathname),
                    type: '点击图标',
                  });
                }}
                className={cx('search-icon-btn')}
              >
                <span className={cx('search-text')}>{intl.t('高级搜索')}</span>
                {getSystemType() === 'mac' ? '⌘+J' : 'ctrl+J'}{' '}
              </span>
            }
          />
        </Popover>
      </div>
    </>
  );
};

export default GlobalSearch;
