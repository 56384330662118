import { intl } from 'di18n-react';
import { isDiDiTenant } from '@/utils/entryEnhance'

const typeMapInDiDI = {
  allPages: {
    name: () => intl.t('全部'),
    type: 0,
    placeholder: () => intl.t('全新升级，快来体验'),
    filterOption: [
      'position',
      'module',
      'owner',
      'tags',
      'times',
      // 'fileType',
    ],
  },
  document: {
    name: () => intl.t('文档'),
    type: 1,
    placeholder: () => intl.t('搜索协作文档、知识库文档'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  excel: {
    name: () => intl.t('表格'),
    type: 2,
    placeholder: () => intl.t('搜索协作表格'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  ppt: {
    name: () => intl.t('幻灯片'),
    type: 3,
    placeholder: () => intl.t('搜索协作幻灯片'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  file: {
    name: () => intl.t('文件'),
    type: 4,
    placeholder: () => intl.t('搜索上传的本地文件、文件夹'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times', 'fileType'],
  },
  line: {
    disabled: true,
    name: () => '|',
    type: 999,
    placeholder: () => intl.t('搜索上传的本地文件、文件夹'),
    filterOption: [],
  },
  // attachment: {
  //   name: intl.t('附件'),
  //   type: 5,
  //   placeholder: intl.t('搜索文档中的附件'),
  //   filterOption: ['owner', 'times', 'fileType'],
  // },
  metis: {
    name: () => intl.t('知识库'),
    type: 6,
    placeholder: () => intl.t('搜索知识库'),
    filterOption: ['position', 'owner'],
  },
  space: {
    name: () => intl.t('空间'),
    type: 7,
    placeholder: () => intl.t('搜索团队/个人空间'),
    filterOption: ['position', 'owner'],
  },
  wiki: {
    name: () => 'Wiki',
    type: 8,
    placeholder: () => intl.t('搜索Wiki页面'),
    filterOption: ['owner'],
  },
};
const typeMapExternalTenant = {
  allPages: {
    name: () => intl.t('全部'),
    type: 0,
    placeholder: () => intl.t('全新升级，快来体验'),
    filterOption: [
      'position',
      'module',
      'owner',
      'tags',
      'times',
      // 'fileType',
    ],
  },
  document: {
    name: () => intl.t('文档'),
    type: 1,
    placeholder: () => intl.t('搜索协作文档'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  excel: {
    name: () => intl.t('表格'),
    type: 2,
    placeholder: () => intl.t('搜索协作表格'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  ppt: {
    name: () => intl.t('幻灯片'),
    type: 3,
    placeholder: () => intl.t('搜索协作幻灯片'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times'],
  },
  file: {
    name: () => intl.t('文件'),
    type: 4,
    placeholder: () => intl.t('搜索上传的本地文件、文件夹'),
    filterOption: ['position', 'module', 'owner', 'tags', 'times', 'fileType'],
  },
  line: {
    disabled: true,
    name: () => '|',
    type: 999,
    placeholder: () => intl.t('搜索上传的本地文件、文件夹'),
    filterOption: [],
  },
  space: {
    name: () => intl.t('团队空间'),
    type: 7,
    placeholder: () => intl.t('搜索团队空间'),
    filterOption: ['position', 'owner'],
  },
}
export const getSearchTypeMap = () => {
  if (isDiDiTenant()) {
    return typeMapInDiDI;
  }
  return typeMapExternalTenant;
}
export const scopeMap = {
  PERSONAL: -1,
  STAR: 'star',
  TEAMS: -2,
  DKS: -3,
}
