import { intl, getLocale } from 'di18n-react';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import LazyLoad from 'react-lazyload';
import { isDC } from '@/utils/index';
import { createDocByTemplate } from '@/service/cooper/template';
import Loading from '@/components/Loading/index';
import './index.less';
import { CooperEmptyImg } from '@/assets/icon/index';

const ScrollHeader = (props) => (
  <div className="modal-title">
    <div
      className={`${
        props.fileType === 0 ? 'item-text-checked' : ''
      } modal-title-item`}
      onClick={() => props.chooseFileType(0)}
    >
      <div className="item-text">{intl.t('表格')}</div>
      <div
        className={`${props.fileType === 0 ? 'item-checked' : ''} item-line`}
       />
    </div>
    <div
      className={`${
        props.fileType === 1 ? 'item-text-checked' : ''
      } modal-title-item  modal-title-item-right`}
      onClick={() => props.chooseFileType(1)}
    >
      <div className="item-text">{intl.t('文档')}</div>
      <div
        className={`${props.fileType === 1 ? 'item-checked' : ''} item-line`}
       />
    </div>
  </div>
);

class TemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.reqTemplateType(0);
    this.setState({
      loading: true,
    });
    this.props.reqAllTemplateList({
      fileType: 0,
      cb: () => {
        this.setState({
          loading: false,
        });
      },
    });
  }

  chooseTemplateType = (index) => {
    const { fileType } = this.props;
    const { templateTypeList: dataSource4Type } = this.props;
    this.setState({
      loading: true,
    });

    let templateTypeId = null;
    if (index === -1) {
      // 全部模板
      templateTypeId = -1;
      // if (fileType === 0) __mirrorSendEvent('SHEET_ALL_TYPE_CLICK');
      // else if (fileType === 1) __mirrorSendEvent('DOC_ALL_TYPE_CLICK');
    } else if (index === -2) {
      // 推荐模板
      templateTypeId = 0;
    } else {
      templateTypeId = dataSource4Type[index].id;
    }

    if (index !== -1) {
      const typeName = index === -2 ? intl.t('推荐模板') : dataSource4Type[index].name;
      if (fileType === 0) {
        // sendEvent('SHEET_TYPE_CLICK', 1, {
        //   action: typeName, name: typeName
        // });
      } else if (fileType === 1) {
        // sendEvent('DOC_TYPE_CLICK', 1, {
        //   action: typeName, name: typeName
        // });
      }
    }
    this.props.reqTemplateList({
      templateTypeId,
      fileType,
      cb: () => {
        this.setState({
          loading: false,
        });
      },
    });
    this.props.changeSubTemplate(index);
  };

  chooseFileType = (fileType) => {
    this.props.changeFileType(fileType);
    this.props.changeSubTemplate(-1);
    this.props.reqTemplateType(fileType);
    this.setState({
      loading: true,
    });
    this.props.reqAllTemplateList({
      fileType,
      cb: () => {
        this.setState({
          loading: false,
        });
      },
    });
    this.scrollContainer.scrollTop = 0;
    // if (fileType === 0) __mirrorSendEvent('SHEET_CLICK');
    // else if (fileType === 1) __mirrorSendEvent('DOC_CLICK');
  };

  createByTemplate = (templateId, templateName) => {
    const { fileType } = this.props;
    const { isCreating } = this.state;
    if (!isCreating) {
      this.setState({
        isCreating: true,
      });

      let win = null;
      if (!isDC()) {
        win = window.open('about:blank');
        win.document.title = intl.t('创建文档中...');
      }
      createDocByTemplate({ templateId, fileType })
        .then((newUrl) => {
          this.setState({
            isCreating: false,
          });
          if (isDC()) {
            window.open(newUrl);
          } else {
            win.location.replace(newUrl);
          }
        })
        .catch(() => {
          if (win) win.close();
        });
    }
    window.__OmegaEvent(
      fileType === 0
        ? 'ep_template_sheet_all_use_ck'
        : 'ep_template_docs_all_use_ck', '', {
        platform: 'new',
      },
    );

    if (fileType === 0) {
      // sendEvent('SHEET_CREATE_CLICK', 1, {action: templateName, name: templateName});
    } else if (fileType === 1) {
      // sendEvent('DOC_CREATE_CLICK', 1, {action: templateName, name: templateName});
    }
  };

  goPreviewTemplate = ({
    previewData: currentTemplateList,
    index,
    allTypeIndex,
  }) => {
    const { fileType } = this.props;
    let currentTemplateIndex = allTypeIndex !== undefined
      ? this.getCurrentIndex(index, allTypeIndex)
      : index;
    let titleText = currentTemplateList[currentTemplateIndex].name;
    this.props.previewTemplate({
      currentTemplateList,
      currentTemplateIndex,
      titleText,
    });
    if (fileType === 0) {
      // __mirrorSendEvent('SHEET_PREVIEW_CLICK');
      // if (allTypeIndex === 0) __mirrorSendEvent('SHEET_RECENT_TEMPLATE_CLICK');
    } else if (fileType === 1) {
      // __mirrorSendEvent('DOC_PREVIEW_CLICK');
      // if (allTypeIndex === 0) __mirrorSendEvent('DOC_RECENT_TEMPLATE_CLICK');
    }
  };

  getCurrentIndex = (subIndex, allTypeIndex) => {
    const { allTemplateList } = this.props;
    let currentIndex = 0;
    allTemplateList.map((item, index) => {
      if (index < allTypeIndex) {
        currentIndex += item.templateList.length;
      } else if (index === allTypeIndex) {
        currentIndex += subIndex;
      }
    });
    return currentIndex;
  };

  renderTemplateType = (dataSource4Type) => {
    const { typeIndex } = this.props;
    return (
      <div className="template-type-container">
        <div
          className={`${
            typeIndex === -1 ? 'template-type-item-active' : ''
          } template-type-item`}
          onClick={() => this.chooseTemplateType(-1)}
        >
          <p className="template-name">{intl.t('全部模板')}</p>
        </div>
        {
          <div
            className={`${
              typeIndex === -2 ? 'template-type-item-active' : ''
            } template-type-item`}
            onClick={() => this.chooseTemplateType(-2)}
          >
            <p className="template-name">{intl.t('推荐模板')}</p>
          </div>
        }

        {dataSource4Type.length > 0
          && dataSource4Type.map((item, index) => {
            return (
              <div
                key={item.id}
                className={`${
                  typeIndex === index ? 'template-type-item-active' : ''
                } template-type-item`}
                onClick={() => this.chooseTemplateType(index)}
              >
                <p className="template-name">{item.name}</p>
              </div>
            );
          })}
      </div>
    );
  };

  renderTemplateItem = (templateList, allTypeIndex) => {
    const { fileType } = this.props;
    const { isCreating } = this.state;
    return (
      <div className="one-template-type-main">
        {templateList.map((img, index) => {
          return (
            <div
              className="one-template-container"
              key={img.id + String(index)}
            >
              <div
                className={'template-img-container'}
                onClick={() => this.createByTemplate(img.id, img.name)}
              >
                <div className="hover-container">
                  <LazyLoad
                    height={'100%'}
                    offset={100}
                    once
                    scrollContainer=".template-list-container"
                    scroll
                  >
                    <img
                      src={img.thumbnailUrl}
                      alt=""
                      className={`${
                        fileType === 1 ? 'hover-container-doc' : ''
                      } hover-container-img`}
                    />
                  </LazyLoad>
                </div>
                <div className="hover-button-wrap">
                  <button
                    className="btn-hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      let previewData = allTypeIndex !== undefined
                        ? this.props.previewAllTemplate
                        : templateList;
                      this.goPreviewTemplate({
                        previewData,
                        index,
                        allTypeIndex,
                      });
                    }}
                  >
                    {intl.t('预览')}
                  </button>
                  <Button
                    className="btn-hover btn-hover-use"
                    loading={isCreating}
                  >
                    {intl.t('使用')}
                  </Button>
                </div>
              </div>
              <p className="template-name">{img.name}</p>
            </div>
          );
        })}
      </div>
    );
  };

  renderEmpty = (size) => {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } if (!size) {
      return (
        <div className="empty-wrap">
          <img
            className="empty-img"
            src={CooperEmptyImg}
            alt="" />
          <p className="empty-title">{intl.t('暂无模板')}</p>
        </div>
      );
    }
    return null;
  };

  renderTemplateList = () => {
    const {
      typeIndex,
      allTemplateList,
      subTemplateList,
      templateTypeList: dataSource4Type,
    } = this.props;
    if (typeIndex === -1) {
      return (
        <div
          className="template-list-container"
          ref={(refs) => {
            this.scrollContainer = refs;
          }}
        >
          {allTemplateList.length === 0
            ? this.renderEmpty(allTemplateList.length)
            : allTemplateList.map((item, index) => {
              return (
                <div
                  className="one-template-type-list"
                  key={item.id}>
                  <h1 className="one-template-type-title">{item.name}</h1>
                  <div className="one-template-type-main">
                    {this.renderTemplateItem(item.templateList, index)}
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
    return subTemplateList.length === 0 ? (
      this.renderEmpty(subTemplateList.length)
    ) : (
      <div
        className="template-list-container"
        ref={(refs) => {
          this.scrollContainer = refs;
        }}
        >
        <div className="one-template-type-list">
          <h1 className="one-template-type-title">
            {typeIndex === -2
              ? intl.t('推荐模板')
              : dataSource4Type[typeIndex].name}
          </h1>
          <div className="one-template-type-main">
            {this.renderTemplateItem(subTemplateList)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { fileType, templateTypeList: dataSource4Type } = this.props;
    const { cls } = this.props;
    return (
      <div className={`${cls} template-modal-content`}>
        <ScrollHeader
          fileType={fileType}
          chooseFileType={this.chooseFileType}
        />
        <div className="template-modal-main">
          {this.renderTemplateType(dataSource4Type)}
          {this.renderTemplateList()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const ca = state.Coopertemplate;
  return {
    fileType: ca.fileType,
    typeIndex: ca.typeIndex,
    templateTypeList: ca.templateTypeList,
    allTemplateList: ca.allTemplateList,
    subTemplateList: ca.subTemplateList,
    previewAllTemplate: ca.previewAllTemplate,
  };
};

const mapDispatchToProps = (TemplateList) => {
  const {
    reqTemplateList,
    changeFileType,
    changeSubTemplate,
    reqTemplateType,
    previewTemplate,
    reqAllTemplateList,
  } = TemplateList.Coopertemplate;
  return {
    reqTemplateList: (params) => reqTemplateList(params),
    changeFileType: (params) => changeFileType(params),
    changeSubTemplate: (params) => changeSubTemplate(params),
    reqTemplateType: (params) => reqTemplateType(params),
    previewTemplate: (params) => previewTemplate(params),
    reqAllTemplateList: (params) => reqAllTemplateList(params),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
