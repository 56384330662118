import { useState, useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import ReactDOM from 'react-dom';
import { Tabs, Modal, Input } from 'antd';
import classBind from 'classnames/bind';
import { singleSpaReact } from '@didi/mf-tenon';
import { getSearchTypeMap, scopeMap } from '@/constants/search';
import Result from './Result';
import Filter from './Filter';
import { getLocale } from 'di18n-react';
// import { isWebkit } from '@/utils';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const { TabPane } = Tabs;
/*
微前端引用参数配置
api参数：
search： 搜索接口
recent： 最近访问
dkList： 知识库list
userList： 搜索用户接口
tagList： 搜索tag接口
mode: 环境 |prod|test|development
*/

const defaultFilter = {
  owners: [],
  locationIds: [],
  tagIds: [],
  moduleTypes: [],
  time: '',
  fileType: '0',
}

const AdvanceSearch = (wrapProps) => {
  const props = wrapProps.appProps ?? wrapProps;
  const { visible, closeSearchModal, searchKey, searchScopeId, searchScopeName, api = {}} = props;
  const [inputKey, setInputKey] = useState(searchKey);
  const [value, setValue] = useState();
  const [filter, setFilter] = useState(Object.assign(defaultFilter, {}));
  const [searchType, setSearchType] = useState('allPages');
  const [cnInputting, setCnInputting] = useState(false);
  const curLocale = getLocale();
  const [searchTypeMap] = useState(getSearchTypeMap());

  useEffect(() => {
    if (!visible) return;
    if (!searchScopeId || searchScopeId === '') {
      setFilter(Object.assign(defaultFilter, {}));
    } else if (searchScopeId === scopeMap.STAR) {
      setFilter({
        ...defaultFilter,
        moduleTypes: [searchScopeId],
      })
    } else {
      setFilter({
        ...defaultFilter,
        locationIds: [searchScopeId],
      })
    }
  }, [visible, searchScopeId]);

  const onCompositionStart = () => {
    setCnInputting(true);
  };

  const onCompositionEnd = (e) => {
    setCnInputting(false);
    setValue(e.target.value);
    onInputChange(e.target.value);
    window.__OmegaEvent('ep_search_advanced_entername_ck');
  };

  useEffect(() => {
    setInputKey(searchKey);
    setValue(searchKey);
  }, [searchKey])

  const loadOptions = (key) => {
    setInputKey(key);
  };
  const onInputChange = useMemo(() => {
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchTypeUpdate = (v) => {
    setSearchType(v);

    window.__OmegaEvent('ep_search_advanced_tab_ck', '高级搜索-切换tab', {
      source: v,
    });
  }

  // 弹窗关闭没有销毁，因此手动重置数据
  const closeModal = () => {
    closeSearchModal && closeSearchModal();
    setFilter(Object.assign(defaultFilter, {}));
    setSearchType('allPages');
    setInputKey('');
    setValue('');
  }

  const headerRender = (<div
    className={cx({
      'search-modal-header': true,
    })}>
    <div
      className={cx({
        'search-header-left': true,
        'search-header-left-en': curLocale === 'en-US',
      })}>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
        style={{ color: '#bbb', fontSize: 20 }}
      />
      <Input
        allowClear
        autoFocus={true}
        size="large"
        value={value}
        placeholder={searchTypeMap[searchType].placeholder()}
        bordered={false}
        defaultValue={searchKey}
        onPressEnter={(e) => {
          onInputChange(e.target.value);
          window.__OmegaEvent('ep_search_advanced_entername_ck');
        }}
        onChange={(e) => {
          setValue(e.target.value);
          if (cnInputting) {
            return;
          };
          onInputChange(e.target.value);
          window.__OmegaEvent('ep_search_advanced_entername_ck');
        }}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
      />
    </div>

    <div
      className={cx('search-header-right')}
      onClick={closeModal}
    >
      <i
        className={`${cx({
          'ant-input-clear-icon': true,
          'dk-iconfont': true,
        })}`}
      />
    </div>
  </div>);

  const isFilter = useMemo(() => {
    let arr = Object.keys(filter);
    for (let i = 0; i < arr.length; i++) {
      if (!['[]', '', '0'].includes(filter[arr[i]].toString())) return true;
    }
    return false;
  }, [filter]);

  const showRecent = !isFilter && (!inputKey || inputKey === '');

  return (
    <Modal
      visible={visible}
      wrapClassName={`${cx('search-modal-wrap')} search-modal-wrap`}
      className={cx('search-page-wrap')}
      title={headerRender}
      width='90%'
      closeIcon={<></>}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
    >
      <div className={cx('search-page-tab')}>
        <Tabs
          activeKey={searchType}
          onChange={handleSearchTypeUpdate}>
          {
            Object.keys(searchTypeMap).map((key, index) => {
              return <TabPane
                disabled={searchTypeMap[key].disabled}
                tab={searchTypeMap[key].name()}
                key={key} />
            })
          }
        </Tabs>
      </div>
      <div
        className={cx('search-page-content')}
      >
        <Result
          onClickMore={handleSearchTypeUpdate}
          searchType={searchType}
          filter={filter}
          keyWord={inputKey}
          isRecent={showRecent}
          close={closeModal}
          api={api} />
        <Filter
          searchScopeId={searchScopeId}
          searchScopeName={searchScopeName}
          filter={filter}
          onChange={setFilter}
          selectionMap={searchTypeMap[searchType].filterOption}
          api={api} />
      </div>
    </Modal>
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: AdvanceSearch,
});

export const { bootstrap } = reactLifecycles;
export const { mount } = reactLifecycles;
export const { unmount } = reactLifecycles;

export default AdvanceSearch;
