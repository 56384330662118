/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-15 14:51:29
 * @LastEditTime: 2024-03-20 18:32:12
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/cooperFile.js
 *
 */
import { post, patch, get } from '@/utils/request/cooper.js';
import { collaborativeDocumentTypes } from '@/constants/cooper';
import api from '@/utils/request/api/CooperApi';
import { message } from 'antd';
import { intl } from 'di18n-react';
import { isDiDiTenant } from '@/utils/entryEnhance';


export function getDownloadUrlAction(ids, spaceId, region) {
  const url = `${api.API_DOWNLOADS.replace(':teamId', spaceId)}?region=${region}`;
  return post(url, ids);
}

export function moveFile(id, parent, fromTeamId, toTeamId) {
  return () => {
    return post(api.DIRECTORY_MOVE, {
      resource_ids: id,
      parent_id: Number(parent),
      from_team_id: fromTeamId,
      to_team_id: toTeamId,
    }, {
      keepSilent: true,
    });
  };
}

export function newNameAction(id, name) {
  return () => patch(api.RESOURCES_RENAME.replace(':resourceId', id), {
    name,
  });
}

// eslint-disable-next-line camelcase
export const createExcelRequest = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    parent_id, file_type: collaborativeDocumentTypes.excel,
  }, {
    keepSilent: true,
  })
    .then((data) => { return data.resourceId }).catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
}

// eslint-disable-next-line camelcase
export const createExcel2Request = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    parent_id, file_type: collaborativeDocumentTypes.excel2,
  }, {
    keepSilent: true,
  })
    .then((data) => { return data.resourceId }).catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
}

// eslint-disable-next-line camelcase
export const createWordRequest = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    parent_id, file_type: collaborativeDocumentTypes.word,
  }, {
    keepSilent: true,
  })
    .then((data) => { return data.resourceId })
    .catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
}

// eslint-disable-next-line camelcase
export const createWord2Request = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), { parent_id, file_type: collaborativeDocumentTypes.word2 }, { keepSilent: true })
    .then((data) => { return data.resourceId }).catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
};

// eslint-disable-next-line camelcase
export const createAnonymousWordRequest = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), { parent_id, file_type: collaborativeDocumentTypes.anonymousWord }, { keepSilent: true })
    .then((data) => { return data.resourceId }).catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
}


// eslint-disable-next-line camelcase
export const createDiDocWordRequest = (parent_id, teamId) => {
  return post(api.SPACE_DOC.replace(':teamId', teamId), { parent_id, file_type: collaborativeDocumentTypes.didoc }, { keepSilent: true })
    .then((data) => { return data.resourceId }).catch((err) => {
      if (err.errorCode === 301082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    });
}

// eslint-disable-next-line camelcase
export async function createSlideRequest(parent_id, teamId) {
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    parent_id,
    file_type: collaborativeDocumentTypes.slide,
  }, { keepSilent: true }).then((res) => {
    return res.resourceId
  }).catch((err) => {
    if (err.errorCode === 301082) {
      message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
    } else {
      message.error(err.message)
    }
    return Promise.reject(err);
  });
}

// eslint-disable-next-line camelcase
export async function createSlide2Request(parent_id, teamId) {
  return post(api.SPACE_DOC.replace(':teamId', teamId), {
    parent_id,
    file_type: collaborativeDocumentTypes.slide2,
  }, { keepSilent: true }).then((res) => {
    return res.resourceId
  }).catch((err) => {
    if (err.errorCode === 301082) {
      message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
    } else {
      message.error(err.message)
    }
    return Promise.reject(err);
  })
}


export const importExcelRequest = (parentId, content, fileName, teamId) => post(api.SPACE_DOC_IMPORT.replace(':teamId', teamId), {
  docType: collaborativeDocumentTypes.excel,
  fileName,
  parentId,
  content,
}).then((data) => data.resourceId);

export const importWordRequest = (parentId, content, fileName, teamId) => post(api.SPACE_DOC_IMPORT.replace(':teamId', teamId), {
  docType: collaborativeDocumentTypes.word,
  fileName,
  parentId,
  content,
}).then((data) => data.resourceId);

export const createDrawFileRequest = (parent_id) => // eslint-disable-line camelcase
  // eslint-disable-next-line implicit-arrow-linebreak
  post(api.CREATE_DRAW, { parent_id })
    .then((data) => data.resourceId);

export const updateSpaceConfigs = ({ spaceId, configs }) => post(api.SPACE_PROFILE, {
  spaceId,
  configs,
});

export async function searchAddTeamMember({ keyword, searchType, resourceId, itemSize, pageNum }) {
  const result = await get(`${api.ADD_MEMBER_SEARCH}?keyword=${encodeURIComponent(keyword)}&searchType=${searchType}&teamId=${resourceId}&itemSize=${itemSize}&pageNum=${pageNum || 0}`);
  return result;
}
