import { useState, useRef, useEffect, useMemo } from 'react';
import { Input, Popover } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import PositionOptions from './PositionOptions';


const cx = classBind.bind(styles);

const SelectAnyOption = (props) => {
  const { placeholder, reset, onChoose = () => {}, OptionRender, showRecent = true } = props;
  const [visible, setVisible] = useState();

  const [inputValue, setInputValue] = useState();


  useEffect(() => {
    if (reset) {
      setInputValue(undefined);
    }
  }, [reset]);

  const handleVisibleChange = (v) => {
    setVisible(v);
  };

  const optionContent = () => {
    const onChooseCallback = (v) => {
      onChoose && onChoose(v);
      handleVisibleChange(false);
      setInputValue(null);
    }
    return <div className={cx('options')}>
      {
        OptionRender
          ? OptionRender({
            searchValue: inputValue,
            onChooseCallback,
          })
          : <PositionOptions
              searchValue={inputValue}
              onChoose={onChooseCallback}
          />
     }
    </div>
  }

  const showOption = useMemo(() => {
    return showRecent || (inputValue && inputValue?.length > 0)
  }, [showRecent, inputValue]);

  return (
    <div className={cx('position-select-wrap')}>
      <Popover
        content={showOption ? optionContent : ''}
        visible={visible}
        trigger="click"
        placement={'bottom'}
        onVisibleChange={handleVisibleChange}
        destroyTooltipOnHide={true}
        overlayClassName={cx('position-search-popover')}
      >
        <Input
          className={cx('input-content')}
          placeholder={placeholder}
          allowClear
          value={inputValue}
          onChange={(e) => {
            const { value } = e.target;
            setInputValue(value);
            if (!value || value.length === 0 || value.includes('undefined')) {
              onChoose({
                value: null,
                label: null,
              }, null);
            }
            handleVisibleChange(value.length > 0);
          }}
        />
      </Popover>
    </div>
  );
};

export default SelectAnyOption;
