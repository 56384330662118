import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { formatRecentTime, isDesktopDC } from '@/utils';
import FileContent from '@/components/common/FileContent';
import handlePositionClick from '@/components/RecentActivity/goToPath';
import { handleFileClick as goToPath } from '@/utils/file';
import { unknown } from '@/assets/icon/fileIcon';
import { FileType } from '@/constants/cooper';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.less';
import { useMemo } from 'react';
import { IN_OUT } from '@/constants/space'

const cx = classBind.bind(styles);

/**
 *
 * @param {teamId} 团队空间时为团队空间Id,个人空间时为0
 * @param {spaceId} 团队空间或个人空间Id
 * @param {knowledgeId} 知识库Id
 * @returns
 */
const SearchItem = ({
  data,
  callback,
  config = {},
  api = {},
  wrapClass = '',
  position,
  isShare = false,
}) => {
  const { description = '' } = config;
  const {
    iconImage = unknown,
    resourceId,
    resourceName,
    resourceOwner,
    scopeName,
    resourceType: fileTypeStr,
    knowledgeId,
    dkShareType,
    pageId,
    shareId,
    shareLink,
    teamId,
    spaceId,
    markedQuickVisit,
    visitTime,
    desc,
    spaceType,
    parentId,
    modifyTime,
    isShareWithMe,
    metisId,
    linkUrl,
    id,
    region,
    relationTypeTags,
  } = data;
  const navigate = useNavigate()

  const isShowOuter = useMemo(() => {
    return region === 2 || (relationTypeTags || []).includes(IN_OUT)
  }, [data])

  const publishDesc = visitTime ? intl.t('浏览于{slot0}', {
    slot0: formatRecentTime(visitTime),
  }) : null;

  const refreshDesc = modifyTime ? intl.t('更新于{slot0}', {
    slot0: formatRecentTime(modifyTime),
  }) : null;

  const jumpTo = () => {
    callback && callback(position, data);
    goToPath({
      fileTypeStr,
      resourceId,
      resourceName: resourceName.replace(/§§/g, ''),
      knowledgeId: knowledgeId || metisId,
      dkShareType,
      pageId: pageId || resourceId,
      shareId,
      shareLink,
      teamId,
      spaceId,
      markedQuickVisit,
      spaceType,
      linkUrl,
      filesList: [{ ...data, name: data.resourceName?.replace(/§§/g, ''), id: data.resourceId }],
      hasOperation: false,
    });
  };

  const onPositionClick = () => {
    const params = {
      resourceTypeStr: fileTypeStr,
      teamId: (fileTypeStr === FileType.DK_FILE || fileTypeStr === FileType.DK_PAGE) ? knowledgeId : teamId,
      shareLink,
      shareId: !!shareId || isShareWithMe,
      id: parentId ?? 0,
      deleted: false,
    }

    handlePositionClick(params, '', navigate, true); // isNewWindow
  }

  return (
    <li
      key={data.resourceId}
      className={`${cx('search-item')} ${wrapClass}`}
      onClick={jumpTo}
    >
      <FileContent
        iconImage={iconImage}
        resourceName={resourceName}
        description={description || desc || null}
        scopeName={scopeName}
        resourceOwner={resourceOwner || null}
        fileTypeStr={fileTypeStr}
        publishDesc={publishDesc}
        refreshDesc={refreshDesc}
        resourceId={resourceId}
        tags={
          fileTypeStr === FileType.DK_PAGE && data.shareId
            ? [intl.t('访客链接')]
            : null
        }
        isShowOuter={isShowOuter}
        onClick={jumpTo}
        onPositionClick={onPositionClick}
        spaceType={spaceType}
      />
    </li>
  );
};

export default SearchItem;
